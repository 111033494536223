import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
        <SEO title={frontmatter.title} />
        <div className="page-container">
        <div className="page">
            {frontmatter.last_updated && <h2>Updated: {frontmatter.last_updated}({frontmatter.version && <a href="/release_notes" class="version">{frontmatter.version}</a  >})</h2>}
            <div
            className="page-content"
            dangerouslySetInnerHTML={{ __html: html }}
            />
        </div>
        </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        last_updated(formatString: "MMMM DD, YYYY"),
        version,
        path
        title
      }
    }
  }`
